/* No CSS */
/*# sourceMappingURL=style.css.map */

@mixin font-face($family, $file, $path, $svg, $weight: normal, $style: normal) {
  @font-face {
    font-family: $family;
    src: url($path + $file + '.eot');
    src: url($path + $file + '.eot?#iefix') format('embedded-opentype'),
    url($path + $file + '.woff') format('woff'),
    url($path + $file + '.ttf') format('truetype'),
    url($path + $file + '.svg##{$svg}') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

@include font-face('Gotham', 'GothamBlack', '../fonts/Gotham/', 'GothamBlack', 900, normal);
@include font-face('Gotham', 'Gotham-Bold', '../fonts/Gotham/', 'Gotham-Bold', 700, normal);
@include font-face('Gotham', 'Gotham-Medium', '../fonts/Gotham/', 'Gotham-Medium', 500, normal);
@include font-face('Gotham', 'Gotham-Book', '../fonts/Gotham/', 'Gotham-Book', 400, normal);
@include font-face('Gotham', 'Gotham-Light', '../fonts/Gotham/', 'Gotham-Light', 300, normal);

:root {
  --ct-body-font-family: "Gotham";
  --ct-body-font-size: 1rem;
  --ct-body-font-weight: 400;
  --ct-body-line-height: 1.5;
  --ct-body-color: #58595B;
  --ct-body-bg: #fafbfe;
  --ct-body-text-align: left;
  --ct-link-color: #3688fc;
  --ct-link-hover-color: #2966bd;
  // --ct-button-primary:#022D51;
  --ct-button-primary: #003A63;
  --ct-nav-user-bg-dark-topbar: #3c4655;
  --ct-nav-user-border-dark-topbar: #414d5d;
  --ct-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  --ct-box-shadow-sm: 0 .125rem .25rem rgba(0, 0, 0, 0.075);
  --ct-box-shadow-lg: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  --ct-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --ct-dropdown-color: #838c96;
  --ct-dropdown-bg: #fff;
  --ct-dropdown-border-color: #e7ebf0;
  --ct-dropdown-box-shadow: var(--ct-box-shadow);
  --ct-dropdown-link-color: #838c96;
  --ct-dropdown-link-hover-color: #40454d;
  --ct-dropdown-link-hover-bg: #f4f6fb;
  --ct-dropdown-link-active-color: #474d56;
  --ct-dropdown-link-active-bg: #f2f5f9;
  --ct-dropdown-link-disabled-color: #98a6ad;
  --ct-page-title-color: inherit;
  --ct-card-border-color: #eef2f7;
  --ct-card-box-shadow: var(--ct-box-shadow);
  --ct-card-cap-bg: #fff;
  --ct-card-bg: #fff;
  --ct-input-bg: #fff;
  --ct-input-disabled-bg: #eef2f7;
  --ct-input-color: #838c96;
  --ct-input-border-color: #dee2e6;
  --ct-input-box-shadow: var(--ct-box-shadow-inset);
  --ct-input-focus-bg: var(--ct-input-bg);
  --ct-input-focus-border-color: #c8cbcf;
  --ct-input-focus-color: var(--ct-input-color);
  --ct-input-placeholder-color: #adb5bd;
  --ct-input-plaintext-color: #838c96;
  --login-color: #003A63;
  --heading-color: #003A63;
  --ct-td-disable-bg: #f2f4f8;
  --ct-td-disable-color: #777;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--ct-body-font-family) !important; 
}

body {
  margin: 0;
  font-family: var(--ct-body-font-family) !important;
  font-size: var(--ct-body-font-size);
  font-weight: var(--ct-body-font-weight);
  line-height: var(--ct-body-line-height);
  color: var(--ct-body-color);
  text-align: var(--ct-body-text-align);
  background-color: var(--ct-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

a {
  color: var(--ct-link-color);
  text-decoration: none;
}

.btn {
  // padding: 0.5rem 1rem !important;
  padding: 0.4rem .75rem !important;
  font-weight: 500;
}

.btn-primary {
  background-color: var(--ct-button-primary) !important;
  border: 2px solid var(--ct-button-primary) !important;
  border-color: var(--ct-button-primary) !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 
              0px 2px 2px 0px rgba(0, 0, 0, 0.14), 
              0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;

  &:hover {
    color: var(--ct-button-primary) !important;
    background-color: #fff !important;
    border: 2px solid var(--ct-button-primary) !important;
  }
}

.btn-outline-primary {
  border: 2px solid !important;
  border-color: var(--ct-button-primary) !important;
  color: var(--ct-button-primary) !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 
  0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;

  &:hover {
    background-color: var(--ct-button-primary) !important;
    color: #fff !important;
  }
}

.btn-secondary {
  border: 2px solid #6c757d;
box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 
              0px 2px 2px 0px rgba(0, 0, 0, 0.14), 
              0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  &:hover {
    color: #6c757d;
    background-color: #fff;
    border-color: #6c757d;
  }
}

.btn-outline-secondary {
  border: 2px solid;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 
  0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  &:hover {
    color: #6c757d;
    background-color: #fff;
    border-color: #6c757d;
  }
}

.btn-blue {
  background-color: #3981A6;
  border: 2px solid #3981A6;
  color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 
  0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  &:hover {
    background-color: #fff;
    color: #3981A6;
  }
}

.btn-outline-blue {
  background-color: #fff;
  border: 2px solid #3981A6;
  color: #3981A6;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 
  0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  &:hover {
    background-color: #3981A6;
    color: #fff;
  }
}

.btn-icon-link {
  color: var(--ct-link-color);

  font-size: 0.875 !important;

  i {
    color: #F58025;
  }

  &:hover {
    background-color: #eef2f7;

    i {
      color: #F58025;
    }
  }
}

table {
  tr.disable {
    td {
      color: var(--ct-td-disable-color);
      background-color: var(--ct-td-disable-bg);
    }
  }
}

td.disable {
  color: var(--ct-td-disable-color);
  background-color: var(--ct-td-disable-bg);
}

.content-page {
  margin-left: 0 !important;
  padding: 0 0 60px 0;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
}

.container-fluid {
  max-width: 92%;
}

.topnav-navbar-dark {
  /* background-color: var(--ct-bg-dark-topbar); */
  background-color: #002036;
}

.topnav-navbar {
  padding: 0;
  margin: 0;
  height: 70px;
  position: relative;
  left: 0 !important;
  z-index: 1002;

  .topnav-logo {
    line-height: 70px;
    padding-right: 20px;
    float: left;
    display: flex;
    align-items: center;
    height: 100%;

    img {
      width: 130px;
      height: auto;
    }

    .topnav-logo-sm {
      display: none;
    }
  }
}

/*right*/
.navbar-custom {
  .topbar-menu {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    li {
      float: left;
      max-height: 70px;
    }

    .nav-link {
      padding: 0;
      color: #98a6ad;
      min-width: 32px;
      display: flex;
      align-items: center;
      text-align: center;
      margin: 0 10px;
      position: relative;
      font-weight: 600;
      min-height: 70px;

      i {
        font-size: .9rem;
      }
    }

    .topnav-menu {
      padding: 0;
    }
  }
}

.dropdown-toggle::after {
  display: none;
}

.arrow-down {
  display: inline-block;

  &:after {
    border-color: initial;
    border-style: solid;
    border-width: 0 0 1px 1px;
    content: "";
    height: 0.4em;
    display: inline-block;
    right: 5px;
    top: 50%;
    margin-left: 10px;
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%);
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
    width: 0.4em;
  }
}

.notification-list {
  margin-left: 0;
}

.nav-user {
  padding: 0 15px !important;
  text-align: left !important;
  position: relative;
  background-color: #fafbfd;
  border: 1px solid #f1f3fa;
  border-width: 0 1px;
  min-height: 70px;
  display: flex !important;
  align-items: center;

  .account-user-avatar {
    margin-right: 15px;

    img {
      height: 32px;
      width: 32px;
    }
  }

  .account-user-name {
    display: block;
    font-weight: 600;
  }

  .account-position {
    display: block;
    font-size: 12px;
    margin-top: -3px;
  }
}

.topnav-navbar-dark .nav-user {
  background-color: var(--ct-nav-user-bg-dark-topbar) !important;
  border: 1px solid var(--ct-nav-user-border-dark-topbar) !important;
}


.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: inherit;
  white-space: nowrap;
}

.dropdown-menu {
  -webkit-box-shadow: var(--ct-box-shadow);
  box-shadow: var(--ct-box-shadow);
  border: 1px solid var(--ct-dropdown-border-color);
  border-radius: 0.25rem;
  min-width: 192px;
}

.profile-dropdown {
  min-width: 192px;
}

.dropdown-menu-animated.dropdown-menu-end[style] {
  left: auto;
  right: 0;
}

.dropdown-menu-animated {
  -webkit-animation-name: DropDownSlide;
  animation-name: DropDownSlide;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  margin: 0;
  z-index: 1000;
}

.dropdown-menu-animated.show {
  top: 100% !important;
}

.dropdown-menu-animated i {
  display: inline-block;
}

.dropdown-menu-animated.dropdown-menu[data-popper-placement^="left"],
.dropdown-menu-animated.dropdown-menu[data-popper-placement^="right"],
.dropdown-menu-animated.dropdown-menu[data-popper-placement^="top"] {
  top: auto !important;
  -webkit-animation: none !important;
  animation: none !important;
}

@-webkit-keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}

@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}

.notification-list .noti-title {
  background-color: transparent;
  padding: 15px 20px;
}

.notification-list .topbar-dropdown-menu .notify-item {
  padding: 7px 20px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: var(--ct-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 1rem;

  i {
    font-size: .9rem;
  }

  &:active{
    background-color: #e9ecef !important;
    color: #1e2125 !important;
  }
}

/*Footer*/
.footer {
  border-top: 1px solid rgba(152, 166, 173, .2);
  bottom: 0;
  position: absolute;
  right: 0;
  color: #000;
  left: 0 !important;
  padding: 19px 0 20px;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;

  .footer-links {
    a {
      color: #000;
      margin-left: 1.5rem;
      -webkit-transition: all .4s;
      transition: all .4s;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

/*Page Title*/
.page-title-box {

  .page-title {
    font-size: 18px;
    margin: 0;
    line-height: 75px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--ct-page-title-color);
    font-weight: 600;
  }

  .page-title-right {
    float: right;
    margin-top: 20px;
  }

  .breadcrumb {
    padding-top: 8px;

    .breadcrumb-item+.breadcrumb-item {
      padding-left: 0.5rem;

      &::before {
        float: left;
        padding-right: 0.5rem;
        padding-top: 2px;
        color: #6c757d;
        content: "\f285";
        font-family: bootstrap-icons !important;
        font-size: .75rem;
      }
    }

  }
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--ct-card-bg);
  background-clip: border-box;
  border: 1px solid var(--ct-card-border-color);
  border-radius: 0.25rem;
  border: none;
  -webkit-box-shadow: var(--ct-card-box-shadow);
  box-shadow: var(--ct-card-box-shadow);
  margin-bottom: 24px;

  .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.5rem 1.5rem;

    .header-title {
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      letter-spacing: .02em;
      font-size: .9rem;
      margin-top: 0;
      font-weight: 700;
    }
  }
}

.dataTables_wrapper {
  padding: 20px 0;

  .dataTables_filter {
    justify-content: flex-end;
    display: flex;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.45rem 0.9rem;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--ct-input-color);
  background-color: var(--ct-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--ct-input-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.form-control-sm {
  min-height: calc(1.5em + 0.56rem + 2px);
  padding: 0.28rem 0.8rem;
  font-size: .875rem;
  border-radius: 0.2rem;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  margin-right: 0;
}

.MuiDataGrid-columnHeaders {
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
}

.MuiDataGrid-row:nth-of-type(odd)>* {
  background: #f1f3fa;
}

.MuiTablePagination-root {
  p {
    margin-bottom: 0;
  }
}

/*Login Page*/
.account-pages {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  background: url(../images/bg-pattern-light.svg) center no-repeat;
  background-size: cover;

  .login-wrap {

    .card-header {
      padding: 1.5rem !important;
      background-color: var(--login-color);

      img {
        max-height: 50px;
      }
    }

    .card-body {
      padding: 5rem 2rem !important;
      border-radius: .2rem .2rem 0 0;

      .signin {
        margin-top: 5rem;

        button {
          text-transform: capitalize;
          letter-spacing: 0;
          font-size: 1.075rem;
        }
      }
    }
  }
}

.grid_wrapper {
  height: 500px; 

  &.smallheight{
    height: 400px;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: 500;
    color: #000;
  }

  .MuiDataGrid-columnHeaderTitle,
  .MuiDataGrid-cell {
    font-size: var(--ct-body-font-size) !important;
    font-family: var(--ct-body-font-family);
    color: #000;
  }

  .MuiDataGrid-footerContainer,
  .MuiTablePagination-displayedRows {
    font-family: var(--ct-body-font-family);
  }
}

.form-control,
.form-control input,
.MuiSelect-select,
.MuiAutocomplete-input,
.MuiInputLabel-root {
  font-family: var(--ct-body-font-family) !important;
}

.form-control.autocomplete {
  padding: 0;

  fieldset {
    border: 0;
  }

  .MuiOutlinedInput-root {
    padding: 0 30px 0 9px;
  }

}
.MuiAutocomplete-popper {
  // box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 
  0px 8px 10px 1px rgba(0, 0, 0, 0.14), 
  0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  font-family: var(--ct-body-font-family) !important;

  li {
    font-family: var(--ct-body-font-family) !important;
  }
}

/*Invoice*/
.invoice-wrap p {
  margin-bottom: 7px;
}

.invoice-wrap table {
  tr.disable{
    td{
      padding: 0 0.5rem;
      div{
        padding: 8px 0;
      }
    }
  }
  tr
  {
    td.groupTitle{
      background-color: #3e586d;
      color: #fff;
    } 
    &.collapsed{
      td.groupTitle{
        background-color: #577b96;
        color: #fff;
      }
      td.groupTitle:before{
        content: "\f229";
      }
    }
  }

  td {
    vertical-align: middle;
    padding: 0 0.5rem;
    &.groupTitle{
      font-weight: 700;
      text-transform: uppercase;
      padding: 0.75rem;
      cursor: pointer;
      &::before{
        content: "\f235";
        display: inline-block;
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: normal !important;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: -0.125em;
        margin-right: 7px;
      }
    }
  }
  tr.error{
    td{
      border: 1px solid rgba(168, 0, 0, 0.5);
      background: rgba(168, 0, 0, 0.1);
      div.position-relative{
        &:after{
          content: "\f33a";
          font-family: bootstrap-icons !important;
          position: absolute;
          right: 0;
          top:-3px;
          color: red;
          font-size: 20px;
        }
      }
    }
  }
  tr.deletedRow{
    td{
      background-color: #fff0ba;
    color: #555;
    border: #ffe2ba;
    font-weight: 500;
    text-decoration: line-through;
    }
  }
} 

.total-invoice {
  border-top: 3px double #000;
  border-bottom: 3px double #000;
  padding: 10px 0;
}

.total-invoice h5 {
  margin-bottom: 0;
}

.modal .btn {
  min-width: 80px;
}

/*Tab UI*/
.MuiButtonBase-root {
  font-family: var(--ct-body-font-family) !important;
  text-transform: capitalize !important;
  font-size: inherit !important;
  letter-spacing: 0 !important;

}

.MuiButtonBase-root.Mui-selected,
.MuiTab-root.MuiButtonBase-root.Mui-selected {
  color: var(--ct-link-color) !important;
}

.MuiTabs-indicator {
  background-color: var(--ct-link-color) !important;
}

.MuiTabPanel-root {
  padding: 16px !important;
}

.verticaltbl {

  td,
  th {
    padding: 1rem;
  }

  th {
    width: 250px;
    font-weight: 500;
  }
}

/*File upload*/
.MuiPaper-root { 
  background: #fff !important;
  max-width: 600px;
  margin: auto;
  border: 0 !important;

  .MuiPaper-root {
    background: #f2f2f2 !important;
  }

  .MuiTypography-root {
    font-family: var(--ct-body-font-family) !important;
    font-weight: 400 !important;
  }

  .MuiTypography-caption {
    color: var(--ct-body-color) !important;

    b {
      font-weight: 500 !important;
    }

    .MuiButton-root {
      color: var(--ct-body-color) !important;
      border: 1px solid var(--ct-body-color);
      font-size: 0.9rem !important;
    }
  }

  .MuiGrid-container {
    .MuiGrid-grid-sm-3 {
      display: none !important;
    }

    .MuiGrid-grid-sm-9 {
      flex-basis: 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 200px;
      flex-direction: column;
    }
  }
}

.tabfooterwrap {
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 2px solid var(--ct-dropdown-color);
  text-align: right;
}

.MuiDataGrid-footerContainer .MuiToolbar-root,
.MuiTablePagination-selectLabel {
  font-family: var(--ct-body-font-family) !important;
}

.addForm .form-group{
  margin-bottom: 1.5rem;
  &:last-child{
    margin-bottom: 0;
  }
}

.clienttabsgrid{
  .MuiBox-root {
    .MuiDataGrid-root{
      border: 0;
    }
  }
  .MuiDataGrid-main{
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 4px 4px 0 0;
  }
  .MuiDataGrid-footerContainer{
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 0 0 4px 4px;
    margin-top: -1px;
  } 
}
.autoheightcell .MuiDataGrid-row,
.autoheightcell .MuiDataGrid-cell{
  max-height: unset !important; height: auto !important; overflow: visible !important;
  position: relative;
}

// .autoheightcell .MuiDataGrid-virtualScroller{
//   overflow: visible !important;
// }

/*popover*/
.projectpop-wrap{
  & .projectspop{
    background: #fff; color: #000;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 3px; 
      th{
        text-align: center;
        // background-color: var(--heading-color);
        // color: white;
        border-top: 3px solid var(--heading-color);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        color:var(--heading-color);
        font-size: 1rem;
        padding: 5px 25px;
        border-radius: 3px 3px 0 0; 
      }
      td{
        text-align: center;
        padding: 3px 10px; border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &:hover{
          background-color: rgba(0, 0, 0, 0.03);;
        }
      }
  }
}

.attachfile .MuiPaper-root {
    padding: 0 !important;
}

.attachfile .MuiGrid-root {
    width: 100% !important;
    margin: 0 !important;

    &.MuiGrid-root {
        padding: 0;
    }
}

.attachfile .MuiPaper-root .MuiGrid-container .MuiGrid-grid-sm-9 {
    min-height: unset;
    flex-direction: row;
    width: auto;
    max-width: unset;
    justify-content: flex-start;
}

.attachfile .MuiPaper-root .MuiPaper-root {
    background: #fff !important;
    padding: 0 !important;
}

.attachfile .MuiTypography-root .MuiAvatar-root {
    display: none !important;
}

.attachfile .MuiTypography-root .MuiTypography-caption {
    display: none !important;
}

.attachfile .MuiTypography-root p {
    margin-bottom: 0 !important;
}

.attachfile .MuiTypography-root .MuiTypography-root {
    flex-grow: unset !important;
}

.attachfile .MuiTypography-root {
    display: flex;
    flex-wrap: wrap;
}

.attachfile .MuiTypography-root .MuiPaper-root {
    margin: 0 10px 10px 0 !important;
    background: #f2f2f2 !important;
    border-radius: 20px;
    padding: 2px 10px !important;
}

.attachfile .MuiTypography-root .MuiPaper-root button {
    padding: 0 !important;
    margin: 0 0 0 5px;
}

.attachfile .MuiTypography-root .MuiPaper-root button svg {
    width: 18px;
} 

.attachmentsList {
  flex-wrap: wrap; 
}

.attachmentsList .MuiChip-root{
  margin: 0 10px 10px 0 !important;
}

/*Loader*/
.loader{
  min-height: 500px; width: 100%;
  display: flex; align-items: center; justify-content: center;

  & .MuiCircularProgress-indeterminate {
    width: 80px !important;
    height: 80px !important;
  }
}
.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #26262645;
  z-index: 9999;
}
.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*delete react alert box */ 
.confirm-dialog{
  & .MuiDialog-paper{
   width: 100%;
   max-width: 500px;
 }
   h2.MuiTypography-root{
     margin-bottom: 0;
     line-height: 1.5;
     font-size: 1.25rem;
     font-weight: 500 !important;
   }
 }

 .confirm-dialog-delete{
  & .MuiDialog-paper{
    width: 25%;
   max-width: 500px;
 }
   h2.MuiTypography-root{
     margin-bottom: 0;
     line-height: 1.5;
     font-size: 1.25rem;
     font-weight: 500 !important;
   }
 }

.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 10px 12px;
    background-color: var(--ct-button-primary);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
    height: 16px;
    margin-right: 4px;
}
.moreprojectlink{
  cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    display: block;
    overflow: hidden;
}
/*Approval Workflow*/
.invoice-wrap{padding:0 .75rem;}
.workflow-wrap{
  border-left: 1px solid #ccc;
  padding-bottom: 75px;
  position: relative;
  & .workflow-footer{
    border-top:1px solid #ccc;
    position: absolute;
    padding: 1rem 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.conversationpane{
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-y: scroll;
  height: 400px;
}
.chathistory{
  padding:7px 7px 15px 7px;
  border-bottom:2px solid #000;
  &:last-child{
    padding-bottom: 7px;
    border-bottom: 0;
  }
  & .chatmsg{
    small{
      font-size: 12px;
      display: block;
      text-align: right;
    }
  }
  & .attachFile{
    position: relative;
    margin-top: 5px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
    min-height: 35px;
    display: flex;
    & .filenames{
      display: inline-block;
      padding-right: 10px;
        flex-wrap: wrap;
        & .onlyfile{
          margin-right: 7px;
        }
        & .MuiChip-root{
          margin: 0 5px 5px 0 !important;
        }
    }
    & .file-input{
      position: absolute;
      right: 0;
      top: 5px;
      span{
        font-size: .875rem;
        font-weight: 500;
        margin-right: 7px;
      }
    }
    & .file-input__label{
      padding: 2px;
      background: none;
      color: #3688fc;
      box-shadow: none;
      font-size: 1.5rem;
    }
  }
  & .addchat{
    margin-top: 10px;
    & .iconbtn{
      font-size: 1.2rem;
    }
  }
}
.iconbtn{
  border: 0;
  background: none;
  color: #3688fc;
  padding: 2px;
  font-size: 1.5rem;

  svg{
    display: block !important;
  } 
}
.rotateicon{
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.nottbl{
  width:100%;
  tr{
    td{
      border-bottom:1px solid #ccc;
      padding: 1.5rem 0 1rem;
      strong{
        padding-bottom: 0;
      }
      .form-control{margin: 0 10px; display: inline-block; width: 57px;}
    }
    &:last-child{
      td{
        border: 0;
      }
    }
  }
}
.loadingbtn{
  svg.MuiCircularProgress-svg{
    circle.MuiCircularProgress-circle{
    stroke:#fff !important;
  }
  }
}

.MuiCalendarPicker-root{
  & .MuiButtonBase-root.Mui-selected{
    color: #fff !important;
    font-weight: normal !important;
  }
}

.MuiPaper-root{
  &.MuiPaper-elevation{
    max-width: unset !important;
    & .MuiButtonBase-root{
      font-size: 0.875rem !important;
    }
    & .MuiButtonBase-root.Mui-selected{      
      font-weight: normal !important;
    }
  }
}

.pagination-wrap{
  nav{
    display: flex;
    justify-content: center;
  }
  & .page-item{
    & .page-link{
      border:0 !important;
      width: 36px; height: 36px; line-height: 1.5; border-radius: 100% !important; background-color: #f2f2f2; margin: 0 7px;
      font-weight: 500; color: #002036;
      &:hover{
      background-color: #002036;
      color: #fff;
    }
    }
  } 
}
  
.proxy-dialog{
  display: flex;
  justify-content: center;
  p{
    padding: 9px 10px 0 0;
    margin-bottom: 0;
  }
}


.verticalhr{height: 100%; width:1px; background-color:rgb(225, 225, 225); } 
.form-control.error {
    border-color: #dc3545;
}

.billquntgrid{
  & .MuiDataGrid-row{
    cursor: pointer;
  }
}
.MuiDataGrid-row:hover,
.MuiDataGrid-row:hover:nth-of-type(odd) > *{
  background-color: #d6d8e1 !important;
}

.total-row {
  padding: 8px 0;
  display: block;
}

.panetxtbx fieldset{border:0 !important;}
// .confirm-dialog{
//   & .MuiDialog-paper{
//    width: 100%;
//    max-width: 500px !important;
//  }
//}
.parameters .MuiInputBase-formControl {
  padding-right: 30px;
}
.mappingdiscount {
  .MuiDialog-paper{
    height:83%;
  } 
}
.mappingdiscount .MuiPaper-root.MuiPaper-elevation
{
  max-height:unset !important;
}

.add-mapping {
  .MuiDialog-paper{
    height:60%;
  } 
}
.add-mapping .MuiPaper-root.MuiPaper-elevation
{
  max-height:unset !important;
}