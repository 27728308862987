/* No CSS */
/*# sourceMappingURL=style.css.map */
@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham/GothamBlack.eot");
  src: url("../fonts/Gotham/GothamBlack.eot?#iefix") format("embedded-opentype"), url("../fonts/Gotham/GothamBlack.woff") format("woff"), url("../fonts/Gotham/GothamBlack.ttf") format("truetype"), url("../fonts/Gotham/GothamBlack.svg#GothamBlack") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham/Gotham-Bold.eot");
  src: url("../fonts/Gotham/Gotham-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Gotham/Gotham-Bold.woff") format("woff"), url("../fonts/Gotham/Gotham-Bold.ttf") format("truetype"), url("../fonts/Gotham/Gotham-Bold.svg#Gotham-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham/Gotham-Medium.eot");
  src: url("../fonts/Gotham/Gotham-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Gotham/Gotham-Medium.woff") format("woff"), url("../fonts/Gotham/Gotham-Medium.ttf") format("truetype"), url("../fonts/Gotham/Gotham-Medium.svg#Gotham-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham/Gotham-Book.eot");
  src: url("../fonts/Gotham/Gotham-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/Gotham/Gotham-Book.woff") format("woff"), url("../fonts/Gotham/Gotham-Book.ttf") format("truetype"), url("../fonts/Gotham/Gotham-Book.svg#Gotham-Book") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham/Gotham-Light.eot");
  src: url("../fonts/Gotham/Gotham-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Gotham/Gotham-Light.woff") format("woff"), url("../fonts/Gotham/Gotham-Light.ttf") format("truetype"), url("../fonts/Gotham/Gotham-Light.svg#Gotham-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}
:root {
  --ct-body-font-family: "Gotham";
  --ct-body-font-size: 1rem;
  --ct-body-font-weight: 400;
  --ct-body-line-height: 1.5;
  --ct-body-color: #58595B;
  --ct-body-bg: #fafbfe;
  --ct-body-text-align: left;
  --ct-link-color: #48628d;
  --ct-link-hover-color: #48454f;
  --ct-button-primary: #003A63;
  --ct-nav-user-bg-dark-topbar: #3c4655;
  --ct-nav-user-border-dark-topbar: #414d5d;
  --ct-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  --ct-box-shadow-sm: 0 .125rem .25rem rgba(0, 0, 0, 0.075);
  --ct-box-shadow-lg: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  --ct-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --ct-dropdown-color: #838c96;
  --ct-dropdown-bg: #fff;
  --ct-dropdown-border-color: #e7ebf0;
  --ct-dropdown-box-shadow: var(--ct-box-shadow);
  --ct-dropdown-link-color: #838c96;
  --ct-dropdown-link-hover-color: #40454d;
  --ct-dropdown-link-hover-bg: #f4f6fb;
  --ct-dropdown-link-active-color: #474d56;
  --ct-dropdown-link-active-bg: #f2f5f9;
  --ct-dropdown-link-disabled-color: #98a6ad;
  --ct-page-title-color: inherit;
  --ct-card-border-color: #eef2f7;
  --ct-card-box-shadow: var(--ct-box-shadow);
  --ct-card-cap-bg: #fff;
  --ct-card-bg: #fff;
  --ct-input-bg: #fff;
  --ct-input-disabled-bg: #eef2f7;
  --ct-input-color: #838c96;
  --ct-input-border-color: #dee2e6;
  --ct-input-box-shadow: var(--ct-box-shadow-inset);
  --ct-input-focus-bg: var(--ct-input-bg);
  --ct-input-focus-border-color: #c8cbcf;
  --ct-input-focus-color: var(--ct-input-color);
  --ct-input-placeholder-color: #adb5bd;
  --ct-input-plaintext-color: #838c96;
  --login-color: #003A63;
  --heading-color: #003A63;
  --ct-td-disable-bg: #f2f4f8;
  --ct-td-disable-color: #777;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--ct-body-font-family) !important;
}

body {
  margin: 0;
  font-family: var(--ct-body-font-family) !important;
  font-size: var(--ct-body-font-size);
  font-weight: var(--ct-body-font-weight);
  line-height: var(--ct-body-line-height);
  color: var(--ct-body-color);
  text-align: var(--ct-body-text-align);
  background-color: var(--ct-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

a {
  color: var(--ct-link-color);
  text-decoration: none;
}

.btn {
  padding: 0.4rem 0.75rem !important;
  font-weight: 500;
}

.btn-primary {
  background-color: var(--ct-button-primary) !important;
  border: 1px solid var(--ct-button-primary) !important;
  border-color: var(--ct-button-primary) !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.btn-primary:hover {
  color: var(--ct-button-primary) !important;
  background-color: #fff !important;
  border: 1px solid var(--ct-button-primary) !important;
}

.btn-outline-primary {
  border: 2px solid !important;
  border-color: var(--ct-button-primary) !important;
  color: var(--ct-button-primary) !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.btn-outline-primary:hover {
  background-color: var(--ct-button-primary) !important;
  color: #fff !important;
}

.btn-secondary {
  border: 2px solid #6c757d;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.btn-secondary:hover {
  color: #6c757d;
  background-color: #fff;
  border-color: #6c757d;
}

.btn-outline-secondary {
  border: 2px solid;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.btn-outline-secondary:hover {
  color: #6c757d;
  background-color: #fff;
  border-color: #6c757d;
}

.btn-blue {
  background-color: #3981A6;
  border: 2px solid #3981A6;
  color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.btn-blue:hover {
  background-color: #fff;
  color: #3981A6;
}

.btn-outline-blue {
  background-color: #fff;
  border: 2px solid #3981A6;
  color: #3981A6;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.btn-outline-blue:hover {
  background-color: #3981A6;
  color: #fff;
}

.btn-icon-link {
  color: var(--ct-link-color);
  font-size: 0.875 !important;
  font-weight: 700 !important;
}
.btn-icon-link i {
  color: #F58025;
}
.btn-icon-link:hover {
  background-color: #eef2f7;
}
.btn-icon-link:hover i {
  color: #F58025;
}

table tr.disable td {
  color: var(--ct-td-disable-color);
  background-color: var(--ct-td-disable-bg);
}

td.disable {
  color: var(--ct-td-disable-color);
  background-color: var(--ct-td-disable-bg);
}

.content-page {
  margin-left: 0 !important;
  padding: 0 0 60px 0;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
}

.container-fluid {
  max-width: 92%;
}

.topnav-navbar-dark {
  /* background-color: var(--ct-bg-dark-topbar); */
  background-color: #002036;
}

.topnav-navbar {
  padding: 0;
  margin: 0;
  height: 70px;
  position: relative;
  left: 0 !important;
  z-index: 1002;
}
.topnav-navbar .topnav-logo {
  line-height: 70px;
  padding-right: 20px;
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
}
.topnav-navbar .topnav-logo img {
  width: 130px;
  height: auto;
}
.topnav-navbar .topnav-logo .topnav-logo-sm {
  display: none;
}

/*right*/
.navbar-custom .topbar-menu {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}
.navbar-custom .topbar-menu li {
  float: left;
  max-height: 70px;
}
.navbar-custom .topbar-menu .nav-link {
  padding: 0;
  color: #98a6ad;
  min-width: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 10px;
  position: relative;
  font-weight: 600;
  min-height: 70px;
}
.navbar-custom .topbar-menu .nav-link i {
  font-size: 0.9rem;
}
.navbar-custom .topbar-menu .topnav-menu {
  padding: 0;
}

.dropdown-toggle::after {
  display: none;
}

.arrow-down {
  display: inline-block;
}
.arrow-down:after {
  border-color: initial;
  border-style: solid;
  border-width: 0 0 1px 1px;
  content: "";
  height: 0.4em;
  display: inline-block;
  right: 5px;
  top: 50%;
  margin-left: 10px;
  transform: rotate(-45deg) translateY(-50%);
  transform-origin: top;
  transition: all 0.3s ease-out;
  width: 0.4em;
}

.notification-list {
  margin-left: 0;
}

.nav-user {
  padding: 0 15px !important;
  text-align: left !important;
  position: relative;
  background-color: #fafbfd;
  border: 1px solid #f1f3fa;
  border-width: 0 1px;
  min-height: 70px;
  display: flex !important;
  align-items: center;
}
.nav-user .account-user-avatar {
  margin-right: 15px;
}
.nav-user .account-user-avatar img {
  height: 32px;
  width: 32px;
}
.nav-user .account-user-name {
  display: block;
  font-weight: 600;
}
.nav-user .account-position {
  display: block;
  font-size: 12px;
  margin-top: -3px;
}

.topnav-navbar-dark .nav-user {
  background-color: var(--ct-nav-user-bg-dark-topbar) !important;
  border: 1px solid var(--ct-nav-user-border-dark-topbar) !important;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  white-space: nowrap;
}

.dropdown-menu {
  box-shadow: var(--ct-box-shadow);
  border: 1px solid var(--ct-dropdown-border-color);
  border-radius: 0.25rem;
  min-width: 192px;
}

.profile-dropdown {
  min-width: 192px;
}

.dropdown-menu-animated.dropdown-menu-end[style] {
  left: auto;
  right: 0;
}

.dropdown-menu-animated {
  -webkit-animation-name: DropDownSlide;
  animation-name: DropDownSlide;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  margin: 0;
  z-index: 1000;
}

.dropdown-menu-animated.show {
  top: 100% !important;
}

.dropdown-menu-animated i {
  display: inline-block;
}

.dropdown-menu-animated.dropdown-menu[data-popper-placement^=left],
.dropdown-menu-animated.dropdown-menu[data-popper-placement^=right],
.dropdown-menu-animated.dropdown-menu[data-popper-placement^=top] {
  top: auto !important;
  -webkit-animation: none !important;
  animation: none !important;
}

@-webkit-keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }
  0% {
    transform: translateY(10px);
  }
}
@keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }
  0% {
    transform: translateY(10px);
  }
}
.notification-list .noti-title {
  background-color: transparent;
  padding: 15px 20px;
}

.notification-list .topbar-dropdown-menu .notify-item {
  padding: 7px 20px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: var(--ct-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 1rem;
}
.dropdown-item i {
  font-size: 0.9rem;
}
.dropdown-item:active {
  background-color: #e9ecef !important;
  color: #1e2125 !important;
}

/*Footer*/
.footer {
  border-top: 1px solid rgba(152, 166, 173, 0.2);
  bottom: 0;
  position: absolute;
  right: 0;
  color: #000;
  left: 0 !important;
  padding: 19px 0 20px;
  transition: all 0.2s ease-in-out;
}
.footer .footer-links a {
  color: #000;
  margin-left: 1.5rem;
  transition: all 0.4s;
}
.footer .footer-links a:first-of-type {
  margin-left: 0;
}

/*Page Title*/
.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--ct-page-title-color);
  font-weight: 600;
}
.page-title-box .page-title-right {
  float: right;
  margin-top: 20px;
}
.page-title-box .breadcrumb {
  padding-top: 8px;
}
.page-title-box .breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.page-title-box .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  padding-top: 2px;
  color: #6c757d;
  content: "\f285";
  font-family: bootstrap-icons !important;
  font-size: 0.75rem;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--ct-card-bg);
  background-clip: border-box;
  border: 1px solid var(--ct-card-border-color);
  border-radius: 0.25rem;
  border: none;
  box-shadow: var(--ct-card-box-shadow);
  margin-bottom: 20px;
}
.card .card-body {
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}
.card .card-body .header-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 0.9rem;
  margin-top: 0;
  font-weight: 700;
}

.dataTables_wrapper {
  padding: 0;
}
.dataTables_wrapper .dataTables_filter {
  justify-content: flex-end;
  display: flex;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--ct-input-color);
  background-color: var(--ct-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--ct-input-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.form-control-sm {
  min-height: calc(1.5em + 0.56rem + 2px);
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  margin-right: 0;
}

.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle {
  font-weight: 700;
}

.MuiDataGrid-row:nth-of-type(odd) > * {
  background: #f1f3fa;
}

.MuiTablePagination-root p {
  margin-bottom: 0;
}

/*Login Page*/
.account-pages {
  align-items: center;
  display: flex;
  min-height: 100vh;
  background: url(../images/bg-pattern-light.svg) center no-repeat;
  background-size: cover;
}
.account-pages .login-wrap .card-header {
  padding: 1.5rem !important;
  background-color: var(--login-color);
}
.account-pages .login-wrap .card-header img {
  max-height: 50px;
}
.account-pages .login-wrap .card-body {
  padding: 5rem 2rem !important;
  border-radius: 0.2rem 0.2rem 0 0;
}
.account-pages .login-wrap .card-body .signin {
  margin-top: 5rem;
}
.account-pages .login-wrap .card-body .signin button {
  text-transform: capitalize;
  letter-spacing: 0;
  font-size: 1.075rem;
}

.grid_wrapper {
  height: 500px;
}
.grid_wrapper.smallheight {
  height: 400px;
}
.grid_wrapper .MuiDataGrid-columnHeaderTitle {
  font-weight: 500;
  color: #000;
}
.grid_wrapper .MuiDataGrid-columnHeaderTitle,
.grid_wrapper .MuiDataGrid-cell {
  font-size: var(--ct-body-font-size) !important;
  font-family: var(--ct-body-font-family);
  color: #000;
}
.grid_wrapper .MuiDataGrid-footerContainer,
.grid_wrapper .MuiTablePagination-displayedRows {
  font-family: var(--ct-body-font-family);
}

.form-control,
.form-control input,
.MuiSelect-select,
.MuiAutocomplete-input,
.MuiInputLabel-root {
  font-family: var(--ct-body-font-family) !important;
}

.form-control.autocomplete {
  padding: 0;
}
.form-control.autocomplete fieldset {
  border: 0;
}
.form-control.autocomplete .MuiOutlinedInput-root {
  padding: 0 30px 0 9px;
}

.MuiAutocomplete-popper {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  font-family: var(--ct-body-font-family) !important;
}
.MuiAutocomplete-popper li {
  font-family: var(--ct-body-font-family) !important;
}

/*Invoice*/
.invoice-wrap p {
  margin-bottom: 7px;
}

.invoice-wrap table tr.disable td {
  padding: 0 0.5rem;
}
.invoice-wrap table tr.disable td div {
  padding: 8px 0;
}
.invoice-wrap table tr td.groupTitle {
  background-color: #3e586d;
  color: #fff;
}
.invoice-wrap table tr.collapsed td.groupTitle {
  background-color: #577b96;
  color: #fff;
}
.invoice-wrap table tr.collapsed td.groupTitle:before {
  content: "\f229";
}
.invoice-wrap table td {
  vertical-align: middle;
  padding: 0 0.5rem;
}
.invoice-wrap table td.groupTitle {
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.75rem;
  cursor: pointer;
}
.invoice-wrap table td.groupTitle::before {
  content: "\f235";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  margin-right: 7px;
}
.invoice-wrap table tr.error td {
  border: 1px solid rgba(168, 0, 0, 0.5);
  background: rgba(168, 0, 0, 0.1);
}
.invoice-wrap table tr.error td div.position-relative:after {
  content: "\f33a";
  font-family: bootstrap-icons !important;
  position: absolute;
  right: 0;
  top: -3px;
  color: red;
  font-size: 20px;
}
.invoice-wrap table tr.deletedRow td {
  background-color: #fff0ba;
  color: #555;
  border: #ffe2ba;
  font-weight: 500;
  text-decoration: line-through;
}

.total-invoice {
  border-top: 3px double #000;
  border-bottom: 3px double #000;
  padding: 10px 0;
}

.total-invoice h5 {
  margin-bottom: 0;
}

.modal .btn {
  min-width: 80px;
}

/*Tab UI*/
.MuiButtonBase-root {
  font-family: var(--ct-body-font-family) !important;
  text-transform: capitalize !important;
  font-size: inherit !important;
  letter-spacing: 0 !important;
}

.MuiButtonBase-root.Mui-selected,
.MuiTab-root.MuiButtonBase-root.Mui-selected {
  color: var(--ct-link-color) !important;
}

.MuiTabs-indicator {
  background-color: var(--ct-link-color) !important;
}

.MuiTabPanel-root {
  padding: 16px !important;
}

.verticaltbl td,
.verticaltbl th {
  padding: 1rem;
}
.verticaltbl th {
  width: 250px;
  font-weight: 500;
}

/*File upload*/
.MuiPaper-root {
  background: #fff !important;
  max-width: 600px;
  margin: auto;
  border: 0 !important;
}
.MuiPaper-root .MuiPaper-root {
  background: #f2f2f2 !important;
}
.MuiPaper-root .MuiTypography-root {
  font-family: var(--ct-body-font-family) !important;
  font-weight: 400 !important;
}
.MuiPaper-root .MuiTypography-caption {
  color: var(--ct-body-color) !important;
}
.MuiPaper-root .MuiTypography-caption b {
  font-weight: 500 !important;
}
.MuiPaper-root .MuiTypography-caption .MuiButton-root {
  color: var(--ct-body-color) !important;
  border: 1px solid var(--ct-body-color);
  font-size: 0.9rem !important;
}
.MuiPaper-root .MuiGrid-container .MuiGrid-grid-sm-3 {
  display: none !important;
}
.MuiPaper-root .MuiGrid-container .MuiGrid-grid-sm-9 {
  flex-basis: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  flex-direction: column;
}

.tabfooterwrap {
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 2px solid var(--ct-dropdown-color);
  text-align: right;
}

.MuiDataGrid-footerContainer .MuiToolbar-root,
.MuiTablePagination-selectLabel {
  font-family: var(--ct-body-font-family) !important;
}

.addForm .form-group {
  margin-bottom: 1.5rem;
}
.addForm .form-group:last-child {
  margin-bottom: 0;
}

.clienttabsgrid .MuiBox-root .MuiDataGrid-root {
  border: 0;
}
.clienttabsgrid .MuiDataGrid-main {
  border: 1px solid rgb(224, 224, 224);
  border-radius: 4px 4px 0 0;
}
.clienttabsgrid .MuiDataGrid-footerContainer {
  border: 1px solid rgb(224, 224, 224);
  border-radius: 0 0 4px 4px;
  margin-top: -1px;
}

.autoheightcell .MuiDataGrid-row,
.autoheightcell .MuiDataGrid-cell {
  max-height: unset !important;
  height: auto !important;
  overflow: visible !important;
  position: relative;
}

/*popover*/
.projectpop-wrap .projectspop {
  background: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.projectpop-wrap .projectspop th {
  text-align: center;
  border-top: 3px solid var(--heading-color);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--heading-color);
  font-size: 1rem;
  padding: 5px 25px;
  border-radius: 3px 3px 0 0;
}
.projectpop-wrap .projectspop td {
  text-align: center;
  padding: 3px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.projectpop-wrap .projectspop td:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.attachfile .MuiPaper-root {
  padding: 0 !important;
}

.attachfile .MuiGrid-root {
  width: 100% !important;
  margin: 0 !important;
}
.attachfile .MuiGrid-root.MuiGrid-root {
  padding: 0;
}

.attachfile .MuiPaper-root .MuiGrid-container .MuiGrid-grid-sm-9 {
  min-height: unset;
  flex-direction: row;
  width: auto;
  max-width: unset;
  justify-content: flex-start;
}

.attachfile .MuiPaper-root .MuiPaper-root {
  background: #fff !important;
  padding: 0 !important;
}

.attachfile .MuiTypography-root .MuiAvatar-root {
  display: none !important;
}

.attachfile .MuiTypography-root .MuiTypography-caption {
  display: none !important;
}

.attachfile .MuiTypography-root p {
  margin-bottom: 0 !important;
}

.attachfile .MuiTypography-root .MuiTypography-root {
  flex-grow: unset !important;
}

.attachfile .MuiTypography-root {
  display: flex;
  flex-wrap: wrap;
}

.attachfile .MuiTypography-root .MuiPaper-root {
  margin: 0 10px 10px 0 !important;
  background: #f2f2f2 !important;
  border-radius: 20px;
  padding: 2px 10px !important;
}

.attachfile .MuiTypography-root .MuiPaper-root button {
  padding: 0 !important;
  margin: 0 0 0 5px;
}

.attachfile .MuiTypography-root .MuiPaper-root button svg {
  width: 18px;
}

.attachmentsList {
  flex-wrap: wrap;
}

.attachmentsList .MuiChip-root {
  margin: 0 10px 10px 0 !important;
}

/*Loader*/
.loader {
  min-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader .MuiCircularProgress-indeterminate {
  width: 80px !important;
  height: 80px !important;
}

.header-loader {
  min-height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-loader .MuiCircularProgress-indeterminate {
  width: 80px !important;
  height: 80px !important;
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(38, 38, 38, 0.2705882353);
  z-index: 9999;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*delete react alert box */
.confirm-dialog .MuiDialog-paper {
  width: 100%;
  max-width: 500px;
}
.confirm-dialog h2.MuiTypography-root {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 500 !important;
}
.confirm-dialog-delete .MuiDialog-paper {
  width: 25%;
  max-width: 500px;
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 10px 12px;
  background-color: var(--ct-button-primary);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.moreprojectlink {
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: block;
  overflow: hidden;
}

/*Approval Workflow*/
.invoice-wrap {
  padding: 0 0.75rem;
}

.workflow-wrap {
  border-left: 1px solid #ccc;
  padding-bottom: 75px;
  position: relative;
}
.workflow-wrap .workflow-footer {
  border-top: 1px solid #ccc;
  position: absolute;
  padding: 1rem 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.conversationpane {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-y: scroll;
  height: 400px;
}

.chathistory {
  padding: 7px 7px 15px 7px;
  border-bottom: 2px solid #000;
}
.chathistory:last-child {
  padding-bottom: 7px;
  border-bottom: 0;
}
.chathistory .chatmsg small {
  font-size: 12px;
  display: block;
  text-align: right;
}
.chathistory .attachFile {
  position: relative;
  margin-top: 5px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  min-height: 35px;
  display: flex;
}
.chathistory .attachFile .filenames {
  display: inline-block;
  padding-right: 10px;
  flex-wrap: wrap;
}
.chathistory .attachFile .filenames .onlyfile {
  margin-right: 7px;
}
.chathistory .attachFile .filenames .MuiChip-root {
  margin: 0 5px 5px 0 !important;
}
.chathistory .attachFile .file-input {
  position: absolute;
  right: 0;
  top: 5px;
}
.chathistory .attachFile .file-input span {
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 7px;
}
.chathistory .attachFile .file-input__label {
  padding: 2px;
  background: none;
  color: #3688fc;
  box-shadow: none;
  font-size: 1.5rem;
}
.chathistory .addchat {
  margin-top: 10px;
}
.chathistory .addchat .iconbtn {
  font-size: 1.2rem;
}

.iconbtn {
  border: 0;
  background: none;
  color: #3688fc;
  padding: 2px;
  font-size: 1.5rem;
}
.iconbtn svg {
  display: block !important;
}

.rotateicon {
  transform: rotate(180deg);
}

.nottbl {
  width: 100%;
}
.nottbl tr td {
  border-bottom: 1px solid #ccc;
  padding: 1.5rem 0 1rem;
}
.nottbl tr td strong {
  padding-bottom: 0;
}
.nottbl tr td .form-control {
  margin: 0 10px;
  display: inline-block;
  width: 57px;
}
.nottbl tr:last-child td {
  border: 0;
}

.loadingbtn svg.MuiCircularProgress-svg circle.MuiCircularProgress-circle {
  stroke: #fff !important;
}

.MuiCalendarPicker-root .MuiButtonBase-root.Mui-selected {
  color: #fff !important;
  font-weight: normal !important;
}

.MuiPaper-root.MuiPaper-elevation {
  max-width: unset !important;
}
.MuiPaper-root.MuiPaper-elevation .MuiButtonBase-root {
  font-size: 0.875rem !important;
}
.MuiPaper-root.MuiPaper-elevation .MuiButtonBase-root.Mui-selected {
  font-weight: normal !important;
}

.pagination-wrap nav {
  display: flex;
  justify-content: center;
}
.pagination-wrap .page-item .page-link {
  border: 0 !important;
  width: 36px;
  height: 36px;
  line-height: 1.5;
  border-radius: 100% !important;
  background-color: #f2f2f2;
  margin: 0 7px;
  font-weight: 500;
  color: #002036;
}
.pagination-wrap .page-item .page-link:hover {
  background-color: #002036;
  color: #fff;
}

.proxy-dialog {
  display: flex;
  justify-content: center;
}
.proxy-dialog p {
  padding: 9px 10px 0 0;
  margin-bottom: 0;
}

.verticalhr {
  height: 100%;
  width: 1px;
  background-color: rgb(225, 225, 225);
}

.form-control.error {
  border-color: #dc3545;
}

.billquntgrid .MuiDataGrid-row {
  cursor: pointer;
}

.MuiDataGrid-row:hover,
.MuiDataGrid-row:hover:nth-of-type(odd) > * {
  background-color: #d6d8e1 !important;
}

.total-row {
  padding: 8px 0;
  display: block;
}

.panetxtbx fieldset {
  border: 0 !important;
}/*# sourceMappingURL=style.css.map */
.parameters .MuiInputBase-formControl {
  padding-right: 30px;
}
.mappingdiscount .MuiDialog-paper{
  height:83%;
}
.mappingdiscount .MuiPaper-root.MuiPaper-elevation
{
  max-height:unset !important
}
.add-mapping .MuiDialog-paper
{
    height:30%;
}

.add-mapping .MuiPaper-root.MuiPaper-elevation
{
  max-height:unset !important;
}

/* Navigation */
header {background: #eff2f7;}
header .custom-navbar {box-shadow: 0px 2px 10px #00000080; padding: 12px 0;}
header .custom-navbar .navbar-brand {padding: 0;}
header .custom-navbar .navbar-brand .site-logo {width: 60px; max-height: 60px;}
header .navbar-nav .nav-item {padding-left: 30px; padding-right: 30px;}
header .navbar-nav .nav-item .nav-link {color: var(--ct-link-color); font-weight: 500; padding-left: 0; padding-right: 0;}
header .navbar-nav .nav-item.nav-toggle-link {padding-left: 15px;}
header .navbar-nav .nav-item .nav-link:hover {color: var(--ct-link-hover-color);}
header .custom-navbar .navbar-collapse {justify-content: space-between; align-items: center;}
header .navbar-nav .nav-item .nav-icon {color: var(--ct-link-hover-color); font-size: 26px;}
header .navbar-nav .nav-item .nav-icon .MuiSvgIcon-root {height: 2.5rem; width: 2.5rem;}
header .custom-navbar .custom-navbar-navigation {align-items: center; display: flex; width: 100%;} 
header .custom-navbar .custom-navbar-navigation.general-navbar-navigation li:last-child {margin-left: auto;}
header .custom-navbar .custom-navbar-navigation.navbar-right-navigation {justify-content: flex-end;}
header .custom-navbar .custom-navbar-navigation.navbar-right-navigation .dropdown .dropdown-toggle::after {right: -16px;}
header .custom-navbar .custom-navbar-navigation.navbar-right-navigation .nav-item.user-menu {padding-right: 0;}
header .custom-navbar .custom-navbar-navigation.navbar-right-navigation .nav-item,header .custom-navbar .custom-navbar-navigation.navbar-right-navigation .nav-item .nav-link {display: flex; align-items: center; height: 100%;}
header .custom-navbar .custom-navbar-navigation.navbar-right-navigation .nav-item.user-menu:hover .dropdown-menu{  display: block !important;} 
header .custom-navbar .custom-navbar-navigation .nav-item .user-icon { display: flex; align-items: center; cursor: default}
header .custom-navbar .custom-navbar-navigation .nav-item .user-label { font-size: 16px; margin-right: 0.3rem;}

/* Admin Tables and General Tables */
/* User details */
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {max-width: none; width: calc(97.5vw - 20px);}
.gridUserMgmtTbl ,.gridActivityMgmtTbl ,.gridPrjMgmtTbl ,.custom-details-grid {max-height: calc(100vh - 4rem - 190px); min-height: 20rem;} 
.report-timedata .MuiDataGrid-virtualScroller {overflow-y: auto !important;} 
.report-accounting .MuiDataGrid-virtualScroller {overflow-y: auto !important;} 
.modal-details-grid {max-height: none; min-height: 650px; overflow-y: auto; overflow-x: hidden;}
.page-container {background: #fafbfe; padding: 24px; padding-top: 18px; padding-right: 48px;}
.custom-details-grid .MuiDataGrid-toolbarContainer.css-128fb87-MuiDataGrid-toolbarContainer {display: flex; align-items: flex-end;  justify-content: flex-end;}
.dataTables_filter .add-user-button,.custom-details-grid .MuiDataGrid-columnHeaderTitle {font-weight: 700 !important;}
.dataTables_filter {text-align: right;}
.dataTables_filter .add-user-button {letter-spacing: 0 !important; margin: 0 0 1rem !important; padding: 0 !important; background: #ffffff !important;} 
.dataTables_filter .add-user-button:hover, .dataTables_filter .add-user-button:focus {background: #ffffff !important;}
.custom-details-grid .add-user-button .MuiButton-startIcon {margin-right: 4px;}
.custom-details-grid .add-user-button:hover,.custom-details-grid .add-user-button:active{background: #ffffff !important; transition: none !important;}
.custom-details-grid .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row:nth-child(odd),.custom-details-grid .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row:nth-child(2n+1){background: #f1f3fa;}
.custom-details-grid .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row:nth-child(even){background: #ffffff;}
.custom-details-grid .MuiDataGrid-row--lastVisible .MuiDataGrid-cell{border-bottom: none;}
.custom-details-grid .MuiDataGrid-main {border:1px solid rgba(224, 224, 224, 1); }
.custom-details-grid .isactive-col .MuiDataGrid-cellContent {color: #ffffff; text-transform: capitalize; padding: 2px 4px; border-radius: 4px;}
.custom-details-grid .isactive-col .MuiDataGrid-cellContent[title="true"] {background:#027b42;}
.custom-details-grid .isactive-col .MuiDataGrid-cellContent[title="false"] {background:#c80606;}



/* Navigation */
.dropdown .dropdown-toggle {white-space: normal; background: transparent; color: var(--ct-link-color) !important; box-shadow: none !important; font-weight: 500; border: 0;padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x) !important;padding-right: var(--bs-navbar-nav-link-padding-x) !important;
  padding-left: var(--bs-navbar-nav-link-padding-x) !important; position: relative;}
.dropdown .dropdown-toggle::after {display:inline-block; margin-left: 0.85em; position: absolute; border-top: .4em solid; border-right: .4em solid transparent; border-bottom: 0;  border-left: .4em solid transparent; top: 50%; transform: translateY(-50%);}
.dropdown .dropdown-toggle:hover,.dropdown .dropdown-toggle:active,.dropdown .btn:first-child:active {background: transparent; color: var(--ct-link-hover-color) !important;}
.dropdown .dropdown-item {color:var(--ct-link-color); font-weight: 500; }
.dropdown .dropdown-item.active, .dropdown .dropdown-item:active, .dropdown .dropdown-item:hover {background: #eff2f7; color: var(--ct-link-hover-color);}

/* Pagination */
.custom-details-grid .MuiDataGrid-footerContainer {border:1px solid rgba(224, 224, 224, 1);}
.MuiDataGrid-footerContainer .MuiTablePagination-selectLabel,.MuiDataGrid-footerContainer .MuiTablePagination-displayedRows {margin: 0;}

/* Section title */
.page-title-box .page-title {font-size: 1.5rem; font-weight: 700; text-align: left;  margin: 0 0 1rem; line-height: 1.5;}

.section-header {text-align: left;}
.section-header .header-title {font-size: 1.25rem; font-weight: 700; color: #012137;}
.user-detail-card {background: #ffffff; box-shadow: 0 5px 5px #0000000d; border: 0; padding: 0; border-radius: 0; max-height: 100%; height: 100%;}
.user-detail-card.timeentry-card {height: auto;}
.user-detail-card .grid_wrapper {height: calc(100% - 10px);}
.user-detail-card.pages-detail-card .grid_wrapper,.user-detail-card.timeentry-card .grid_wrapper,.user-detail-card.timeentry-card .grid_wrapper,.user-detail-card.card .card-body {height: 100%;}
.user-detail-card .grid_wrapper .css-mj2xxh,.user-detail-card .grid_wrapper .MuiBox-root {max-height: 100%; height: calc(100% - 20px);}
.user-detail-card.pages-detail-card .grid_wrapper .MuiBox-root,.user-detail-card .custom-details-grid.MuiDataGrid-autoHeight {height: 100%;}
.MuiTypography-root.MuiTypography-h6 {font-size: 1rem; font-weight: 700;  color: #012137;}
.btn-primary,.btn-primary:hover,.btn-primary:active {background: #012137 !important; border-color: #012137 !important;}
.btn-primary:hover {color: #012137 !important; background-color: #ffffff !important;}
.btn-icon {display: flex; align-items: center;padding:0.95rem .75rem !important;}
.btn-icon svg {margin-left: 0.5rem;}
.btn-icon.btn-left-icon svg {margin-left: 0; margin-right: 0.5rem;}
.user-details-grid.MuiDataGrid-root {border: none !important;}

/* Modals */
.user-detail-card.modal-detail-card {max-height: 100%; margin: 0;}
.user-detail-card.modal-detail-card .card-body {padding: 0 1.5rem 1.5rem;}
.user-detail-card.modal-detail-card .grid_wrapper {height: calc(100% - 70px);}
.user-detail-card.modal-detail-card.activity-modal-detail-card .grid_wrapper {height: calc(100% - 90px);}
.user-detail-card.custom-detail-card {max-height: 100%;}



/* Custom modal */
.custom-modal .MuiPaper-root.MuiPaper-elevation{min-width: 400px; max-width: 850px !important;}
.custom-modal.timeentry-activity-modal .MuiPaper-root.MuiPaper-elevation { max-width: 1100px !important;}
/* .custom-modal.timeentry-activity-modal .content-min-height { min-height: 580px;}
.custom-modal.timeentry-crewmember-modal .content-min-height { min-height: 760px;} */

/* Loader */
.custom-loader {left: 0; right: 0; top: 0; bottom: 0; text-align: center; display: flex; align-items: center; justify-content: center;}

/* Button */
.btn-bordered { border: 1px solid #012137; background: #ffffff; color: #012137;  border-radius: 20px; padding: 8px 30px !important;}
.btn-bordered.btn-primary {color: #fff;}
.btn-bordered.btn-primary[disabled] {background: #ffffff !important;color: #012137; box-shadow: none !important}
.btn-bordered:hover {background: #012137; color: #ffffff;}

/* Breadcrumb section */
.breadcrumb .breadcrumb-item {color: var(--ct-link-color); font-weight: 500;}
.breadcrumb .breadcrumb-item.active {color: #000000;}
.breadcrumb .breadcrumb-item+.breadcrumb-item::before {color: #000000; content: var(--bs-breadcrumb-divider, ">");}

/* Time Entry */
.gridTimeEntryTbl {max-height: calc(90vh); min-height: calc(85vh - 10rem - 150px)}
.gridTimeEntryTbl .dx-pivotgrid-container .dx-bottom-row .dx-area-row-cell .dx-pivotgrid-vertical-headers {min-height: calc(85vh - 10rem - 150px) !important;}
.gridTimeEntryTbl .dx-pivotgrid-container .dx-bottom-row .dx-area-data-cell .dx-pivotgrid-area {min-height: calc(85vh - 10rem - 150px) !important;}
.dx-pivotgrid.dx-widget {font-family: var(--ct-body-font-family) !important; overflow-x: auto !important;  overflow-y: hidden !important;}
.dx-pivotgrid .dx-pivotgrid-area td {color: #000000 !important; }
.dx-pivotgrid .dx-total.dx-row-total,.dx-pivotgrid .dx-total.dx-grandtotal.dx-row-total {background: #d9d9d9 !important; }
.dx-pivotgrid .dx-row-total.dx-grandtotal.dx-last-cell,.dx-pivotgrid .dx-row-total.dx-grandtotal,.dx-pivotgrid .dx-grandtotal {background: #ffffff !important;}
.dx-pivotgrid .dx-pivotgrid-area-data tbody td {text-align: center !important; padding: 2px !important; white-space: normal !important;}
.dx-pivotgrid .dx-pivotgrid-area .dx-pivotgrid-horizontal-headers td { font-weight: 700;}
.dx-pivotgrid .dx-total.dx-pivotgrid-expanded ,.dx-pivotgrid .dx-pivotgrid-collapsed.dx-pivotgrid-sorted,.dx-pivotgrid .dx-pivotgrid-area td.dx-pivotgrid-collapsed{background: #ffffff !important; color: var(--ct-link-color) !important; font-weight: 700;}
.dx-pivotgrid .dx-pivotgrid-expanded .dx-expand {color: var(--ct-link-color) !important;}
.dx-pivotgrid .dx-grandtotal {font-weight: 400; color: #000000 !important; background: #ffffff !important;}
.dx-pivotgrid .dx-pivotgrid-horizontal-headers .dx-row-total.dx-grandtotal span,.dx-pivotgrid .dx-total.dx-row-total.dx-pivotgrid-expanded span {background: #ffffff !important;}
.dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td {vertical-align: middle; }
.dx-pivotgrid .dx-area-tree-view td.dx-white-space-column,.dx-pivotgrid .dx-area-description-cell, .dx-pivotgrid .dx-column-header, .dx-pivotgrid .dx-data-header, .dx-pivotgrid .dx-total {background: #ffffff !important; }
.dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td, .dx-pivotgrid .dx-pivotgrid-border .dx-area-description-cell, .dx-pivotgrid .dx-pivotgrid-border .dx-area-row-cell, .dx-pivotgrid .dx-pivotgrid-border .dx-data-header, 
.dx-pivotgrid .dx-pivotgrid-border .dx-area-column-cell, .dx-pivotgrid .dx-pivotgrid-border .dx-area-data-cell, .dx-pivotgrid .dx-pivotgrid-border .dx-column-header, .dx-pivotgrid .dx-pivotgrid-border .dx-filter-header,.dx-pivotgrid .dx-pivotgrid-vertical-headers .dx-expand-border {border-color: #e3ebf7 !important;}
.dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td.class-data input, .dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td.class-data span {height: 100%; text-align: center; border:none; background: #e3ebf7; border-bottom: 2px solid var(--ct-link-hover-color) !important;  margin: 0 auto;  border-radius: 0; 
  display: flex; align-items: center; justify-content: center; width: 100%;}
.dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td.class-data input {transition: all .25s ease;}
.dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td.class-data span {cursor: text;}
.dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td.class-data input:focus,.dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td.class-data input:hover {border: none !important; outline: none; border-bottom: 2px solid var(--ct-link-hover-color) !important; transform: translateY(-1px) scale(1.02);  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); transition: all .25s ease;}
.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-expanded,.dx-pivotgrid .dx-pivotgrid-area td.dx-pivotgrid-collapsed,.dx-pivotgrid .dx-pivotgrid-vertical-headers .dx-last-cell { text-align: left;}
.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-expanded>span,.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-collapsed>span {white-space: normal !important; width: 280px; display: flex;}
.dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td.dx-row-total.dx-total {border-top: 8px solid var(--ct-link-color) !important;}
.dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td.dx-grandtotal span,.dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td.dx-row-total.dx-total span {display: flex; align-items: center; justify-content: center; height: 100%;}
.dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td.dx-row-total.dx-total.dx-pivotgrid-expanded span {display: inline-block;}
.btn-bordered[disabled]:hover,.btn-bordered[disabled]:focus, .btn-bordered.btn-primary[disabled]:hover,.btn-bordered.btn-primary[disabled]:focus {cursor: not-allowed; background: #ffffff; color: #012137;}
.disabled-cell { background: #f5f2f2; cursor: not-allowed;}
.button-wicker-wrapper {display: flex; align-items: center;}


/* Button */
.page-button-section, .page-button-section .button-wrapper {display: flex; align-items: center; justify-content: flex-end;}
.page-button-section.timeentry-button-section {justify-content: space-between;}

.user-detail-card.custom-user-detail-card, .user-detail-card.custom-user-detail-card .grid_wrapper,.custom-detail-card .custom-details-grid.MuiDataGrid-autoHeight{height: 100%;}
.user-detail-card.custom-user-detail-card .card-body {padding: 0 1.5rem;}

input[type="search"].MuiInputBase-input::-webkit-search-decoration,
input[type="search"].MuiInputBase-input::-webkit-search-cancel-button,
input[type="search"].MuiInputBase-input::-webkit-search-results-button,
input[type="search"].MuiInputBase-input::-webkit-search-results-decoration { display: none; }
 
/* clears the ‘X’ from Internet Explorer */
input[type=search].MuiInputBase-input::-ms-clear { display: none; width : 0; height: 0; }
input[type=search].MuiInputBase-input::-ms-reveal { display: none; width : 0; height: 0; }

/* Form */
.fullwidth .MuiTextField-root {width: 100%;}
.MuiInputLabel-root.MuiInputLabel-animated {padding: 0 5px; background: #ffffff;}


/* time entry week picker*/
.week-picker-display { min-width: 255px; max-width: 280px; min-height: 42px; max-height: 70px; color: #454545; position: relative; border-radius: 8px; cursor: pointer; display: flex; align-items: center; justify-content: space-between; border: 1px solid #012137; transition: 0.5s ease; padding: 0 1rem; display: flex; justify-content: center; align-items: center; font-size: 1rem; border-radius: 20px; background: #ffffff;}
.week-picker-display p {margin: 0;}
.week-picker-options { padding: 0.4rem; position: absolute; top: 105%; left: 50%; transform: translateX(-50%); width: 100%; border-radius: 5px; border: 1px solid #c3c0cc; display: flex; overflow: hidden; background: white; z-index: 99; display: flex; flex-direction: column; align-items: center; }
.title-week { padding: 0.8rem; font-weight: 700; display: flex; width: 100%; justify-content: space-between; align-items: center; }
.numbers-container { border-top: 1px solid gainsboro; width: 100%; padding: 0.3rem; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; }
.single-number { width: 100%; aspect-ratio: 1; font-size: 0.8rem; background: none; color: black; display: grid; place-items: center; }
.selected-week { color: white; background-color: #48628d; }
.other-month { background: none; color: #c3c0cc; }
.day { background: none; color: black; font-weight: 700;}
.arrow-container { cursor: pointer; width: 25px; height: 25px; transition: 0.2s ease-in; display: flex; justify-content: center; align-items: center; border-radius: 50%; }
.arrow-container svg { stroke: #454545; }
.arrow-container:hover { background-color: #48628d; border-radius: 15%; }
.arrow-container:hover svg { stroke: #fff; fill: #fff; }
.other-month { background: none; color: #c3c0cc; }

/* time entry buttons*/
.button-add-activity-notes { position: absolute; right: 5px; border: none; top: 5px; display: flex; align-items: center; padding: 2px 5px; cursor: pointer; }
.button-add-activity-notes svg { font-size: 1rem; width: 0.75em; }

.fieldset-container fieldset {margin:0; padding: 0;}
.cursor-pointer { cursor: pointer;}

.timeenetry-btn-save { min-height: 42px; }
.timeenetry-btn-addcrew { min-height: 42px; }
.timeenetry-btn-addactivity { min-height: 42px; }

/* summary report  */
.budget-section { background-color: #e7f5d3; }
.todate-section { background-color: #e9f2f7; }
.datafilter-section { background-color: #e8e6ef; }
.remaining-section { background-color: #fff0f0; }
.projected-section { background-color: #ecf9fd; }
.forecasted-section { background-color: #fbfbe2; }
.required-section { background-color: #f7dcda; }
.production-section { background-color: #e1fbe4; }
.dx-header-row > td[role="columnheader"]> div.dx-datagrid-text-content { font-weight: 600; }  
.negative-number { color: #FF0000 !important; }
.positive-number { color: #3B10F0 !important; }
.btn-activitycomplete { color: rgb(25, 118, 210); }
.activity-complete { color: lightgray !important; }
.activity-complete:hover { cursor: default; }
.activity-notcomplete { color: dimgray !important; } 
.activity-notcomplete:hover { cursor: pointer; } 
.MuiButtonBase-root.summary-icon {min-width: auto; padding: 0px;}
.datafilter-section-date .MuiInputLabel-root.MuiInputLabel-animated, .datafilter-section-date .MuiOutlinedInput-root { background-color: #e8e6ef; }
.summary-report .dx-datagrid-rowsview .dx-row>td, .activity-on-page-report .dx-datagrid-rowsview .dx-row>td {text-overflow: unset;}
.MuiButtonBase-root.summary-icon svg { font-size: 1.2rem; }
.word-pre-wrap { white-space: pre-wrap }
.dx-editor-cell .dx-texteditor, .dx-editor-cell .dx-texteditor .dx-texteditor-input {background: none !important; }
.summary-report td[role=columnheader],.activity-on-page-report td[role=columnheader] { text-align: center !important; }
.dx-datagrid-column-chooser .dx-treeview-expander-icon-stub { display: none; }
.summary-report .dx-datagrid-total-footer>.dx-datagrid-content { padding-top: 0px !important; padding-bottom: 0px !important;}
.total-crew-rate { border-top: 1px solid #ddd !important; font-weight: bold; border-right: none !important; border-left: none !important; }
.activity-on-page-report .dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none.dx-last-cell { border-left: none !important; }

/* Timeentry > Add Crew Member */
.timeentry-crewmember-modal .user-details-grid.MuiDataGrid-root {width: calc(100% + 20px); overflow-x: hidden;}
.timeentry-crewmember-modal .user-details-grid.MuiDataGrid-root {min-height: 24rem ; max-height: calc(100vh - 330px);}

/* Timeentry > Add Activity */
.timeentry-activity-modal .user-details-grid.MuiDataGrid-root {width: calc(100% + 20px); overflow-x: hidden;}
.timeentry-activity-modal .user-details-grid.MuiDataGrid-root {min-height: 24rem ; max-height: calc(100vh - 360px);}

/* Activity-on-a-Page */
.activity-on-a-page-card {width: 101%;}
.Autocomplete-tight .MuiInputBase-fullWidth {padding-left: 1px !important; padding-right: 1px !important;} 
.activity-on-page-report .dx-datagrid-table.mode-presentation {font-size: xx-large;}
.activity-on-page-report .dx-datagrid-table.mode-presentation .total-crew-rate {font-size: X-large;}
.activity-on-a-page-card .datafilter-section-date {min-width: 104px; max-width: 152px;}
.activity-on-a-page-card .dx-datagrid .dx-row > td { padding: 4px !important;}

.mb-0 {margin-bottom: 0;}
.dv-report-notes { max-height: 19rem; overflow: auto; margin: 1rem 0 1rem 0.5rem; text-align: left;}
.dv-report-notes div:first-child {border-top: 8px solid #003A63;}
.border-dott { border-bottom: 2px dotted #9b9b9b;}
.bar-tooltip { margin: -10px; }
.bar-tooltip p:last-child { margin-bottom: 0; }
.my-7 { margin: 4.5rem 0}
.tab-container { border: 1px solid #ddd; margin-top: 1rem; } 
/* min-height: 250px; max-height: 330px; overflow-y: auto; */

.mobile-apps { border: 1px solid #000; padding: 5px; display: flex; flex-wrap: wrap; justify-content: center; max-width: 150px; }
.mobile-apps-label { flex-basis: 100%; font-size: 14px; color: var(--ct-link-color); font-weight: 700; }
.mobile-apps .nav-link { padding: 0; cursor: pointer; }
.mobile-apps .mobile-android { margin-right: 20px; }
.mobile-apps .nav-link svg { font-size: 2rem; }

@media only print {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {max-width: none; width: calc(100vw - 20px);}
  .navbar {display: none !important}
  @page {
    margin-left: 0.5in;
    margin-right: 0.5in;
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1600px){
  .activity-on-a-page-card .btn-view-report {min-width: 116px;}
}

@media only screen and (max-width: 2600px){
  /* Timeentry > Add Crew Member */
  .timeentry-crewmember-modal .user-details-grid.MuiDataGrid-root .MuiDataGrid-footerContainer {display: none}
  /* Timeentry > Add Crew Member */
  .timeentry-activity-modal .user-details-grid.MuiDataGrid-root .MuiDataGrid-footerContainer {display: none}
}

@media only screen and (max-width:1366px) {
  header .navbar-nav .nav-item {padding-left: 22px; padding-right: 22px;}

  .dx-pivotgrid .dx-pivotgrid-area.dx-pivotgrid-vertical-headers .dx-scrollable-container table{ width: 350px !important;}
  .dx-pivotgrid .dx-pivotgrid-border .dx-area-data-cell {width: calc(100% - 350px) !important;}

  /* Admin Tables and General Tables */
  .gridUserMgmtTbl ,.gridActivityMgmtTbl ,.gridPrjMgmtTbl ,.custom-details-grid {max-height: 78vh; min-height: 20rem;} 
}

@media only screen and (max-width:1200px) {
  header .navbar-nav .nav-item {padding-left: 15px; padding-right: 15px;}
  .dropdown .dropdown-toggle::after {margin-left: 0.25em;}
  header .custom-navbar .navbar-collapse {flex-wrap: wrap;}

  /* Timeentry > Add Crew Member */
  .timeentry-crewmember-modal .user-details-grid.MuiDataGrid-root {min-height: 19rem;}
  /* Timeentry > Add Activity */
  .timeentry-activity-modal .user-details-grid.MuiDataGrid-root {min-height: 19rem;}
}

@media only screen and (max-width:991px) {

  .dx-pivotgrid .dx-pivotgrid-area.dx-pivotgrid-vertical-headers .dx-scrollable-container table{ width: 200px !important;}
  .dx-pivotgrid .dx-pivotgrid-border .dx-area-data-cell {width: calc(100% - 200px) !important;}
  .dx-pivotgrid .dx-word-wrap .dx-pivotgrid-expanded>span,.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-collapsed>span { width: 160px; }

  /* Header */
  header .custom-navbar .custom-navbar-navigation {flex-wrap: wrap;}

  /* Timeentry */
  .page-button-section,.page-button-section .button-wrapper {flex-wrap: wrap;}
  .page-button-section .button-wrapper {justify-content: flex-start;}
  .week-picker-display,  .page-button-section .button-wrapper .btn-icon {margin: 0 0 1rem;}

  /* Timeentry > Add Crew Member */
  .timeentry-crewmember-modal .user-details-grid.MuiDataGrid-root {min-height: 17rem;}
  /* Timeentry > Add Activity */
  .timeentry-activity-modal .user-details-grid.MuiDataGrid-root {min-height: 17rem;}

}

@media only screen and (max-width:576px) {
  .custom-modal .MuiPaper-root.MuiPaper-elevation{min-width: unset;}
  header .navbar-nav .nav-item.nav-toggle-link {display: none;}

  .dx-pivotgrid .dx-pivotgrid-area.dx-pivotgrid-vertical-headers .dx-scrollable-container table{ width: 100px !important;}
  .dx-pivotgrid .dx-pivotgrid-border .dx-area-data-cell {width: calc(100% - 100px) !important;}

    /* Timeentry */
    .week-picker-display {width: 100%; }
}